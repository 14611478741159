import { useTranslation } from 'react-i18next';
import {
  Title,
  Text,
  SimpleGrid,
  Stack,
  Center,
  Loader,
} from '@liveeo/component-library';
import { SidePanelContainer } from '../Map/SidePanelContainer';
import { useParams } from 'react-router-dom';
import { useBuyers, useResetParams } from '../../hooks';
import { StatusIndicator } from '../../shared/components/StatusIndicator';
import classes from './BuyerDetailPanel.module.css';
import { heightOfSidePanelContent } from '../../helper/side-panel-height';
import { useTracking } from '../../hooks';
import { useEffect } from 'react';

export const BuyerDetailPanel = () => {
  const { t } = useTranslation();
  const { trackEvent } = useTracking();
  const { resetParams } = useResetParams();
  const { isLoading, findBuyerById } = useBuyers();
  const { buyerId } = useParams();
  const buyer = findBuyerById(buyerId || '');

  useEffect(() => {
    trackEvent('Buyer', {
      step: 'buyer-details',
      action: 'open',
    });
  }, [trackEvent]);

  return isLoading ? (
    <Center h={heightOfSidePanelContent}>
      <Loader />
    </Center>
  ) : buyer ? (
    <SidePanelContainer>
      <SidePanelContainer.Header>
        <SidePanelContainer.TitleWithNav
          path={`../buyers?${resetParams()}&sel=${buyerId}`}
          name={buyer.name || ''}
        />
      </SidePanelContainer.Header>
      <div className={classes.info}>
        <SimpleGrid className={classes.grid} cols={2}>
          <Stack gap={0}>
            <Title order={4}>{t('buyer.details.contact')}</Title>
            <Text size="lg">{`${buyer.contactPerson?.firstName || '--'} ${
              buyer.contactPerson?.lastName || '--'
            }`}</Text>
          </Stack>
          <Stack gap={0}>
            <Title order={4}>{t('buyer.details.email')}</Title>
            <Text size="lg">{buyer.contactPerson?.email || '--'}</Text>
          </Stack>
          <Stack gap={0}>
            <Title order={4}>{t('buyer.details.address')}</Title>
            <Text size="lg">{buyer.addressLine1 || '--'}</Text>
            <Text size="lg">{buyer.addressLine2 || '--'}</Text>
            <Text size="lg">{buyer.addressCity || '--'}</Text>
            <Text size="lg">{buyer.addressCountry || '--'}</Text>
          </Stack>
          {buyer.status && (
            <Stack gap={0}>
              <Title order={4}>{t('buyer.details.status')}</Title>
              <StatusIndicator text={buyer.status} />
            </Stack>
          )}
        </SimpleGrid>
      </div>
    </SidePanelContainer>
  ) : null;
};
