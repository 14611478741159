import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useQueryClient } from '@tanstack/react-query';
import moment from 'moment';
import {
  Button,
  Divider,
  Text,
  Stack,
  Center,
  Loader,
  Flex,
  Icon,
  ActionIcon,
  Group,
  Image,
} from '@liveeo/component-library';
import { NoNotifications } from './NoNotifications';
import {
  useBusinessInvitations,
  useInvitedBusinesses,
  useInvitationStatus,
  useResetParams,
  useTracking,
} from '../../hooks';
import {
  BusinessInvitation,
  InvitationStatus,
  InvitedSupplier,
} from '../../shared/types';
import { heightOfSidePanelContent } from '../../helper/side-panel-height';
import { Link } from 'react-router-dom';
import USER_ICON from '../../../assets/user.svg';
import classes from './Notifications.module.css';

type InvitationProps = {
  id: string;
  businessId: string;
  name: string | undefined;
  createdAt: string;
};

enum SideEffects {
  ACCEPTED = 'ACCEPTED',
  REJECTED = 'REJECTED',
  PENDING = 'PENDING',
  FAILED = 'FAILED',
}

const Invitation = ({ id, businessId, name, createdAt }: InvitationProps) => {
  const { t } = useTranslation();
  const queryClient = useQueryClient();
  const { trackEvent } = useTracking();
  const { status } = useInvitationStatus();
  const { updateInvitation } = useInvitedBusinesses();
  const { resetParams } = useResetParams();
  const [sideEffect, setSideEffect] = useState<SideEffects>(
    SideEffects.PENDING
  );

  return (
    <>
      {sideEffect === SideEffects.FAILED ? (
        <Flex align="center" justify="space-between" p={15} h={135}>
          <Text>
            {t('notifications.supplier.declined')} <b>{name}</b>
          </Text>
          <Button
            variant="outline"
            className={classes.button}
            onClick={async () => {
              try {
                await updateInvitation({
                  id,
                  update: { status: InvitationStatus.ACCEPTED },
                });
                setSideEffect(SideEffects.ACCEPTED);
                trackEvent('Notifications', {
                  step: 'connection-received',
                  action: 'accept',
                });
              } catch (e: any) {
                console.log('error updating invitation', e.message);
              }
            }}
          >
            {t('common.retry')}
          </Button>
        </Flex>
      ) : sideEffect === SideEffects.REJECTED ? (
        <Flex align="center" justify="space-between" p={15} h={135}>
          <Text>
            {t('notifications.supplier.declined')} <b>{name}</b>
          </Text>
          <ActionIcon
            className={classes['cancel-btn']}
            onClick={() => {
              queryClient.setQueryData(
                ['business-invitations', status],
                (prevInvites: InvitedSupplier[] | undefined) =>
                  prevInvites?.filter(
                    (invite: InvitedSupplier) => invite.id !== id
                  )
              );
            }}
          >
            <Icon icon="xmark" />
          </ActionIcon>
        </Flex>
      ) : sideEffect === SideEffects.ACCEPTED && businessId ? (
        <Flex align="center" justify="space-between" p={15} h={135}>
          <Text>
            {t('notifications.supplier.accepted')} <b>{name}</b>
          </Text>
          <Button
            variant="outline"
            leftSection={<Image src={USER_ICON} w={20} />}
            component={Link}
            to={`../buyers/${businessId}?${resetParams()}`}
          >
            {t('notifications.buyer.action')}
          </Button>
        </Flex>
      ) : (
        <Stack gap={5} p={15} h={135}>
          <Text mb={10}>
            <b>{name}</b> {t('notifications.supplier.access')}
          </Text>
          <Stack gap="sm">
            <Text size="xs">{moment(createdAt).fromNow()}</Text>
            <Group gap={10}>
              <Button
                w={100}
                variant="outline"
                className={classes.button}
                onClick={async () => {
                  try {
                    await updateInvitation({
                      id,
                      update: { status: InvitationStatus.REJECTED },
                    });
                    setSideEffect(SideEffects.REJECTED);
                    trackEvent('Notifications', {
                      step: 'connection-received',
                      action: 'reject',
                    });
                  } catch (e: any) {
                    setSideEffect(SideEffects.FAILED);
                    console.log('error updating invitation', e.message);
                  }
                }}
              >
                {t('common.decline')}
              </Button>
              <Button
                w={100}
                onClick={async () => {
                  try {
                    await updateInvitation({
                      id,
                      update: { status: InvitationStatus.ACCEPTED },
                    });
                    setSideEffect(SideEffects.ACCEPTED);
                    trackEvent('Notifications', {
                      step: 'connection-received',
                      action: 'accept',
                    });
                  } catch (e: any) {
                    setSideEffect(SideEffects.FAILED);
                    console.log('error updating invitation', e.message);
                  }
                }}
              >
                {t('common.accept')}
              </Button>
            </Group>
          </Stack>
        </Stack>
      )}
      <Divider />
    </>
  );
};

export const ReceivedInvitations = () => {
  const queryClient = useQueryClient();
  const { status } = useInvitationStatus();
  const { data: invitations, isLoading } = useBusinessInvitations(
    status || undefined
  );
  const isEmpty = !isLoading && !invitations?.length;

  useEffect(() => {
    // Invalidating cache when component unmounts
    // if action has been taken on all pending invitations
    // the notification bubble will not be displayed when component unmounts
    return () => {
      queryClient.invalidateQueries({
        queryKey: ['business-invitations', status],
      });
    };
  }, [queryClient, status]);

  return (
    <>
      {isLoading ? (
        <Center h={heightOfSidePanelContent}>
          <Loader />
        </Center>
      ) : isEmpty ? (
        <NoNotifications />
      ) : (
        <>
          {invitations?.map((invite: BusinessInvitation) => (
            <Invitation
              key={invite.id}
              id={invite.id}
              name={invite.sentByUser?.business?.name}
              businessId={invite.sentByUser?.business?.id}
              createdAt={invite.createdAt}
            />
          ))}
        </>
      )}
    </>
  );
};
