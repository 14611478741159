import { useQuery, useMutation } from '@tanstack/react-query';
import { useNotification } from './useNotification';
import { useFetchApi } from './useFetchApi';
import {
  InvitedSupplier,
  InvitationPayload,
  InvitationStatus,
} from '../shared/types';
import { InviteStatus } from './useInvitationStatus';

type InvitePayload = {
  email: string;
};

export const useInvitedBusinesses = (status?: InviteStatus | undefined) => {
  const fetch = useFetchApi();
  const { apiError } = useNotification();

  const { data, isLoading } = useQuery({
    queryKey: ['invited-businesses', status],
    queryFn: () =>
      fetch(`business-invitations/sent?status=${status}`) as Promise<
        InvitedSupplier[]
      >,
    onError: apiError,
    enabled: !!status,
    refetchOnWindowFocus: false,
  });

  const invitationMutation = useMutation({
    mutationKey: ['invited-businesses', status],
    mutationFn: (payload: InvitationPayload) =>
      fetch(`business-invitations/${payload.id}`, {
        method: 'PATCH',
        body: JSON.stringify(payload.update),
      }),
  });

  const businessInviteMutation = useMutation({
    mutationKey: ['invited-businesses', InvitationStatus.PENDING],
    mutationFn: (payload: InvitePayload) =>
      fetch('business-invitations', {
        method: 'POST',
        body: JSON.stringify(payload),
      }),
  });

  const updateInvitation = (payload: InvitationPayload) =>
    invitationMutation.mutateAsync(payload, {
      onError: apiError,
    });

  return { data, isLoading, updateInvitation, businessInviteMutation };
};
