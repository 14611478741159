import { useMutation, useQueryClient, useQuery } from '@tanstack/react-query';
import { useFetchApi } from './useFetchApi';
import { UpdatePlot } from '../shared/types';
import { Plot } from '../shared/types';
import { useNotification } from './useNotification';
import { booleanEqual } from '@turf/turf';
import { usePlots } from './usePlots';
import { useLocation, useNavigate } from 'react-router-dom';

export const usePlot = (plotId?: string) => {
  const fetch = useFetchApi();
  const queryClient = useQueryClient();
  const { apiError, successfullyUpdated, successfullyDeleted } =
    useNotification();
  const { requestPlotAnalysis } = usePlots();
  const navigate = useNavigate();
  const location = useLocation();

  const { data, isLoading } = useQuery({
    queryKey: ['plot', plotId],
    queryFn: () => fetch(`plots/${plotId}`) as Promise<Plot>,
    enabled: !!plotId,
    onError: apiError,
    refetchOnWindowFocus: false,
  });

  const updateMutation = useMutation({
    mutationKey: ['plot', plotId],
    mutationFn: (payload: UpdatePlot) =>
      fetch(`plots/${plotId}`, {
        method: 'PATCH',
        body: JSON.stringify(payload),
      }),
  });

  const deleteMutation = useMutation({
    mutationKey: ['plot'],
    mutationFn: (plotId: string) =>
      fetch(`plots/${plotId}`, {
        method: 'DELETE',
      }),
  });

  const updatePlot = (payload: UpdatePlot) =>
    updateMutation.mutateAsync(payload, {
      onSuccess: async (plot: any) => {
        successfullyUpdated('plot');
        queryClient.setQueryData(['plots'], (prevPlots: Plot[] | undefined) =>
          prevPlots?.map((p: Plot) => (p.id === plot.id ? plot : p))
        );
        // Trigger plot analysis re-run when geometry has changed
        if (data && !booleanEqual(data.geometry, plot.geometry)) {
          await requestPlotAnalysis(plot.id, plot.name);
        }
        queryClient.setQueryData(['plot', plot.id], plot);
        queryClient.invalidateQueries({ queryKey: ['plots'] });
      },
      onError: apiError,
    });

  const deletePlot = (plotId: string) => {
    deleteMutation.mutateAsync(plotId, {
      onSuccess: () => {
        successfullyDeleted('plot');
        queryClient.setQueryData(['plots'], (prevPlots: Plot[] | undefined) =>
          prevPlots?.filter((p: Plot) => p.id !== plotId)
        );
        queryClient.invalidateQueries({ queryKey: ['plots'] });
        navigate(
          {
            pathname: '/map/plots',
            search: location.search,
          },
          {
            replace: true,
          }
        );
      },
      onError: apiError,
    });
  };

  return { data, deletePlot, isLoading, updatePlot };
};
