import React from 'react';
import { CustomSVGProps } from './CustomSVG.interface';

export const Share2: React.FC<CustomSVGProps> = ({
  color = 'none',
  style,
  ...props
}) => {
  return (
    <svg
      width={style?.fontSize}
      height={style?.fontSize}
      style={style}
      fill={color}
      color={color}
      stroke={color}
      viewBox="0 0 24 24"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        d="M18.75 22C20.4759 22 21.875 20.6569 21.875 19C21.875 17.3431 20.4759 16 18.75 16C17.0241 16 15.625 17.3431 15.625 19C15.625 20.6569 17.0241 22 18.75 22Z"
        stroke="inherit"
        stroke-width="1.5"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
      <path
        d="M6.25 15C7.97589 15 9.375 13.6569 9.375 12C9.375 10.3431 7.97589 9 6.25 9C4.52411 9 3.125 10.3431 3.125 12C3.125 13.6569 4.52411 15 6.25 15Z"
        stroke="inherit"
        stroke-width="1.5"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
      <path
        d="M8.94727 13.51L16.0618 17.49"
        stroke="inherit"
        stroke-width="1.5"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
      <path
        d="M18.75 8C20.4759 8 21.875 6.65685 21.875 5C21.875 3.34315 20.4759 2 18.75 2C17.0241 2 15.625 3.34315 15.625 5C15.625 6.65685 17.0241 8 18.75 8Z"
        stroke="inherit"
        stroke-width="1.5"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
      <path
        d="M16.0514 6.51001L8.94727 10.49"
        stroke="inherit"
        stroke-width="1.5"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
    </svg>
  );
};
