import MapboxDraw from '@mapbox/mapbox-gl-draw';
import { useEffect } from 'react';
import type { ControlPosition } from 'react-map-gl';
import { useControl } from 'react-map-gl';
import { MapContextValue } from 'react-map-gl/dist/esm/components/map';
import { useLayerState, usePlotState, useTableState } from '../../hooks';

type Props = {
  draw: any;
  onDeletePlot: () => void;
  onDrawPlot: (e: any) => void;
};

type DrawPolygonProps = ConstructorParameters<typeof MapboxDraw>[0] & {
  position?: ControlPosition;
  onCreate: (evt: { features: object[] }) => void;
  onDelete: (evt: { features: object[] }) => void;
  onUpdate: (evt: { features: object[]; action: string }) => void;
};

const DrawControl = (props: DrawPolygonProps | any) => {
  useControl<MapboxDraw>(
    () => props.draw,
    ({ map }: MapContextValue) => {
      map.on('draw.create', props.onCreate);
      map.on('draw.delete', props.onDelete);
      map.on('draw.update', props.onUpdate);
    },
    ({ map }: MapContextValue) => {
      map.off('draw.create', props.onCreate);
      map.off('draw.delete', props.onDelete);
      map.off('draw.update', props.onUpdate);
    }
  );
  return null;
};

export const DrawPolygon = ({ draw, onDrawPlot, onDeletePlot }: Props) => {
  const state = usePlotState();
  const { setLayerState } = useLayerState();
  const { setTableState } = useTableState();

  useEffect(() => {
    /* 
      Clear any previously drawn polygons from the draw state
      when transitioning from draw-plot to plot-details map state.
    */
    draw.current.deleteAll();
    if (state) {
      draw.current.changeMode(state?.mode);
    }
    /* 
      Each map state will have a default layer state
      which will be reset when the map state is changed.

      It will also be a way for the user to reset the layer state
      to it's default when required.

      As the UX relating to layer visibility is still under review,
      this is subject to change.
    */
    setLayerState(state?.defaultState || {});
    setTableState(state?.tableState || {});
  }, [state]);

  return (
    <DrawControl
      displayControlsDefault={false}
      draw={draw.current}
      onCreate={onDrawPlot}
      onDelete={onDeletePlot}
      onUpdate={onDrawPlot}
    />
  );
};
