import { useState, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { PlotsTable } from '../Plots/PlotsTable';
import { usePlots, useToggleAside, useTracking } from '../../hooks';
import {
  ActionIcon,
  Modal,
  Title,
  Image,
  Flex,
  Text,
  Icon,
} from '@liveeo/component-library';
import { SidePanelContainer } from './SidePanelContainer';
import { PlotsImport } from '../Plots/PlotsImport';
import { Table } from '../../shared/components/Table';
import UploadCloud from '../../../assets/upload-cloud.svg';
import classes from './Map.module.css';

const EmptyTable = () => {
  const { t } = useTranslation();
  const { toggleAside } = useToggleAside();

  return (
    <Table.EmptyState>
      <Flex justify="center" align="center" gap="md">
        <Image src={UploadCloud} width={25} />
        <Icon icon="draw-polygon" size="xl" />
      </Flex>
      <Title ta="center" order={3}>
        {t('plots.table.noData.title')}
      </Title>
      <Text ta="center">{t('plots.table.noData.text')}</Text>
      <Table.EmptyState.Footer handleClose={() => toggleAside(undefined)} />
    </Table.EmptyState>
  );
};

export const PlotsTablePanel = () => {
  const { t } = useTranslation();
  const [isButtonDisabled, setIsButtonDisabled] = useState<boolean>(false);
  const [isImportModalOpen, setIsImportModalOpen] = useState<boolean>(false);
  const { data: plots, isLoading } = usePlots();
  const { trackEvent } = useTracking();
  const isEmpty = !isLoading && !plots?.length;
  const height = window.innerHeight - (58 + 20);

  useEffect(() => {
    if (isImportModalOpen) {
      trackEvent('Map', {
        step: 'import-plots',
        action: 'init',
      });
    }
  }, [trackEvent, isImportModalOpen]);

  const toggleImportModal = (boolean: boolean) => {
    if (!isButtonDisabled) setIsImportModalOpen(boolean);
  };

  return (
    <>
      <SidePanelContainer>
        <SidePanelContainer.Header>
          <SidePanelContainer.Title title={t<string>('plots.title')} />
          <ActionIcon
            onClick={() => toggleImportModal(true)}
            bg="transparent"
            data-testid="upload-plots-button"
          >
            <div className={classes.iconState} />
          </ActionIcon>
        </SidePanelContainer.Header>
        {isLoading ? (
          <Table.Loader height={height} />
        ) : isEmpty ? (
          <EmptyTable />
        ) : (
          <PlotsTable plots={plots} height={height} />
        )}
      </SidePanelContainer>
      <Modal
        size={500}
        opened={isImportModalOpen}
        onClose={() => toggleImportModal(false)}
        title={
          <div>
            <Title order={3}>{t('plots.import.title')}</Title>
          </div>
        }
        centered
      >
        <PlotsImport
          toggleImportModal={toggleImportModal}
          isButtonDisabled={isButtonDisabled}
          setIsButtonDisabled={setIsButtonDisabled}
        />
      </Modal>
    </>
  );
};
