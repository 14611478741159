import { MapRef } from 'react-map-gl';

import { create } from 'zustand';

type State = {
  hoveredFeatureId: any;
};

type Action = {
  setHoveredFeatureId: (hoveredFeatureId: string | null) => void;
};

const useHoveredPlotId = create<State & Action>((set) => ({
  hoveredFeatureId: null,
  setHoveredFeatureId: (hoveredFeatureId: any) =>
    set(() => ({ hoveredFeatureId })),
}));

export const useHoveredPlotCallback = (map: MapRef | undefined) => {
  const { hoveredFeatureId, setHoveredFeatureId } = useHoveredPlotId();

  const setHoveredFeatureIdRow = (featureId: number) => {
    map?.setFeatureState({ source: 'plots', id: featureId }, { hover: true });
  };

  const clearHoverdFeatureIdRow = (featureId: number) => {
    map?.setFeatureState({ source: 'plots', id: featureId }, { hover: false });
  };

  const setHoveredFeatureIdMap = (e: any, featureId: number) => {
    if (e.features.length) {
      if (featureId !== e.features[0].id) {
        if (featureId !== null) {
          map?.setFeatureState(
            { source: 'plots', id: featureId },
            { hover: false }
          );
        }
        setHoveredFeatureId(e.features[0].id);
        map?.setFeatureState(
          { source: 'plots', id: e.features[0].id },
          { hover: true }
        );
      }
    } else {
      featureId !== null && setHoveredFeatureId(null);
    }
  };

  const clearHoverdFeatureIdMap = (e: any, featureId: number) => {
    if (e.features.length) {
      if (featureId !== null) {
        map?.setFeatureState(
          { source: 'plots', id: featureId },
          { hover: false }
        );
      }
      setHoveredFeatureId(null);
    }
  };

  return {
    hoveredFeatureId,
    setHoveredFeatureId,
    setHoveredFeatureIdRow,
    clearHoverdFeatureIdRow,
    setHoveredFeatureIdMap,
    clearHoverdFeatureIdMap,
  };
};
