import { useMemo } from 'react';
import {
  Text,
  Button,
  Icon,
  Divider,
  Flex,
  ActionIcon,
} from '@liveeo/component-library';
import { useTranslation } from 'react-i18next';
import { Table } from '../../../shared/components/Table';
import {
  createColumnHelper,
  flexRender,
  getCoreRowModel,
  useReactTable,
  getSortedRowModel,
  SortingState,
  OnChangeFn,
} from '@tanstack/react-table';
import classes from '../Admin.module.css';
import { InvitationActionsMenu } from './InvitationActionsMenu';
import { useTableState } from '../../../hooks';

const columnHelper = createColumnHelper<any>();

type InvitationsTableProps = {
  invitations: any[] | undefined;
};

export const InvitationsTable = ({ invitations }: InvitationsTableProps) => {
  const { t } = useTranslation();
  const { setTableState, sorting } = useTableState();

  const columns = useMemo(() => {
    return [
      columnHelper.accessor((row) => row.name, {
        id: 'name',
        cell: (info) => <Text>{info.getValue()}</Text>,
        header: t<string>('Name'),
      }),
      columnHelper.accessor((row) => row.email, {
        id: 'email',
        cell: (info) => <Text>{info.getValue()}</Text>,
        header: t<string>('Email'),
      }),
      columnHelper.accessor((row) => row.status, {
        id: 'status',
        header: t<string>('Status'),
        cell: (info) => (
          <Button variant="outline" size="sm" className={classes.status}>
            {info.getValue()}
          </Button>
        ),
      }),
      columnHelper.display({
        id: 'actions',
        cell: ({ row }) => (
          <InvitationActionsMenu>
            <InvitationActionsMenu.MenuItem
              onClick={() => console.log({ resend: row.original })}
            >
              <Flex gap="sm">
                <ActionIcon size="sm" color="transparent">
                  <Icon icon="paper-plane" color="dark.0" />
                </ActionIcon>
                <Text>{t<string>('Resend invite')}</Text>
              </Flex>
            </InvitationActionsMenu.MenuItem>
            <Divider />
            <InvitationActionsMenu.MenuItem
              onClick={() => console.log({ delete: row.original })}
            >
              <Flex gap="sm">
                <ActionIcon size="sm" color="transparent">
                  <Icon icon="trash" color="dark.0" />
                </ActionIcon>
                <Text>{t<string>('Remove')}</Text>
              </Flex>
            </InvitationActionsMenu.MenuItem>
          </InvitationActionsMenu>
        ),
      }),
    ];
  }, [t]);

  const table = useReactTable({
    data: invitations || [],
    columns,
    state: {
      sorting,
    },
    getCoreRowModel: getCoreRowModel(),
    onSortingChange: (updaterFunction: OnChangeFn<SortingState> | any) => {
      const [newValue] = updaterFunction(sorting);
      if (newValue) {
        // reset table state when sorting headers used
        setTableState({
          pg: 0,
          sel: undefined,
          ex: undefined,
          sort: newValue.id,
          desc: newValue.desc,
        });
      }
    },
    getSortedRowModel: getSortedRowModel(),
    enableSortingRemoval: false,
  });
  return (
    <Table>
      <Table.Header>
        {table.getHeaderGroups().map((headerGroup) => (
          <Table.HeaderRow key={headerGroup.id}>
            {headerGroup.headers.map((header) => {
              const handleSort = header.column.getToggleSortingHandler();
              return (
                <Table.HeaderCell key={header.id} onClick={handleSort}>
                  <Table.HeaderTitle>
                    {flexRender(
                      header.column.columnDef.header,
                      header.getContext()
                    )}
                  </Table.HeaderTitle>
                  {header.column.getCanSort() ? <Table.SortButtons /> : null}
                </Table.HeaderCell>
              );
            })}
          </Table.HeaderRow>
        ))}
      </Table.Header>
      <Table.Body>
        {table.getRowModel().rows.map((row) => (
          <Table.Row key={row.id}>
            {row.getVisibleCells().map((cell) => (
              <Table.Cell key={cell.id}>
                {flexRender(cell.column.columnDef.cell, cell.getContext())}
              </Table.Cell>
            ))}
          </Table.Row>
        ))}
      </Table.Body>
    </Table>
  );
};
