import { useCallback } from 'react';
import { useQuery } from '@tanstack/react-query';
import { useNotification } from './useNotification';
import { useFetchApi } from './useFetchApi';
import { BusinessConnection } from '../shared/types';

export const useSuppliers = () => {
  const fetch = useFetchApi();
  const { apiError } = useNotification();

  const { data, isLoading, isFetching } = useQuery({
    queryKey: ['suppliers'],
    queryFn: () =>
      fetch('business-connections/suppliers') as Promise<BusinessConnection[]>,
    onError: apiError,
    refetchOnWindowFocus: false,
  });

  const findSupplierById = useCallback(
    (supplierId: string) => {
      if (!data?.length || !supplierId) return;
      return data?.find(
        (connection: BusinessConnection) =>
          connection?.targetBusiness.id === supplierId
      )?.targetBusiness;
    },
    [data]
  );

  return {
    data,
    isLoading,
    isFetching,
    findSupplierById,
  };
};
