import { useTranslation } from 'react-i18next';
import styled from '@emotion/styled';
import { Link, Outlet, useParams } from 'react-router-dom';
import { heightOfSidePanelContent } from '../../helper/side-panel-height';
import { useResetParams } from '../../hooks';
import { SidePanelContainer } from './SidePanelContainer';
import { Button } from '@liveeo/component-library';
import classes from './SidePanelContainer.module.css';

type ContainerProps = {
  height: number;
};

const Container = styled.div(({ height }: ContainerProps) => ({
  height,
  overflow: 'auto',
}));

export const NotificationsPanel = () => {
  const { t } = useTranslation();
  const { resetParams } = useResetParams();
  const params = useParams()['*'];

  return (
    <SidePanelContainer>
      <SidePanelContainer.Header justify="flex-start">
        <SidePanelContainer.Title title={t('notifications.title')} />
        <Button
          className={classes['filter-btn']}
          data-selected={params === 'notifications'}
          component={Link}
          to={`../notifications?${resetParams()}`}
        >
          {t('notifications.supplier.status.received')}
        </Button>
        <Button
          className={classes['filter-btn']}
          data-selected={params !== 'notifications'}
          component={Link}
          to={`../notifications/sent?${resetParams()}`}
        >
          {t('notifications.supplier.status.sent')}
        </Button>
      </SidePanelContainer.Header>
      <Container height={heightOfSidePanelContent}>
        <Outlet />
      </Container>
    </SidePanelContainer>
  );
};
