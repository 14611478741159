import { useEffect } from 'react';
import { useForm, useFieldArray } from 'react-hook-form';
import { joiResolver } from '@hookform/resolvers/joi';
import Joi from 'joi';
import {
  TextInput,
  Stack,
  Flex,
  ActionIcon,
  Button,
  Beta,
} from '@liveeo/component-library';

import {
  useInvitedBusinesses,
  useNotification,
  useTracking,
} from '../../hooks';
import { useTranslation } from 'react-i18next';
import { isObject } from 'lodash';
import classes from './InviteSuppliers.module.css';

type InviteSupplierProps = {
  cancel: () => void;
};

type InviteProps = {
  email: string;
};

type FormDataProps = {
  suppliers: InviteProps[];
};

const InviteSchema = Joi.object().keys({
  suppliers: Joi.array()
    .items(
      Joi.object().keys({
        email: Joi.string()
          .email({ tlds: { allow: false } })
          .required(),
      })
    )
    .unique(),
});

const defaultValues = {
  email: '',
};

export const InviteSuppliersForm = ({ cancel }: InviteSupplierProps) => {
  const { t } = useTranslation();
  const { trackEvent } = useTracking();
  const { businessInviteMutation } = useInvitedBusinesses();
  const { successfullyInvited } = useNotification();
  const {
    register,
    handleSubmit,
    control,
    formState: { errors },
  } = useForm({
    defaultValues: {
      suppliers: [{ ...defaultValues }],
    },
    resolver: joiResolver(InviteSchema),
  });

  useEffect(() => {
    trackEvent('Supplier', {
      step: 'supplier-invite',
      action: 'open',
    });
  }, [trackEvent]);

  const { fields, append, remove } = useFieldArray({
    control,
    name: 'suppliers',
  });

  const onSubmit = async (data: FormDataProps) => {
    // TODO there should be a bulk invite endpoint to handle this
    const apiErrors: string[] = [];
    try {
      await Promise.all(
        data.suppliers.map(async (payload: InviteProps) => {
          try {
            await businessInviteMutation.mutateAsync(payload);
            trackEvent('Supplier', {
              step: 'supplier-invite',
              action: 'sent',
            });
          } catch (error) {
            apiErrors.push(error as string);
            trackEvent('Supplier', {
              step: 'supplier-invite',
              action: 'error',
            });
          }
        })
      );
    } finally {
      // TODO - until we have designs for showing errors we only show the
      // success notification if any of the invitations were sent and
      // we are currently not showing any errors to the user as errors are most likely due to
      // invitng someone who you are already connected with or invitng yourself by mistake
      if (data.suppliers.length > apiErrors.length) {
        successfullyInvited('suppliers');
      }
      cancel();
    }
  };

  return (
    <form onSubmit={handleSubmit(onSubmit)}>
      <Stack>
        {fields.map((field, index) => {
          const isInputError = isObject(errors?.suppliers?.[index]);
          return (
            <Flex
              key={field.id}
              justify="space-between"
              align={isInputError ? 'center' : 'flex-end'}
              direction="row"
            >
              <TextInput
                {...register(`suppliers.${index}.email`)}
                error={
                  errors?.suppliers?.[index]?.message || // to catch duplicate emails in the same form, validation is happening on the array level
                  errors?.suppliers?.[index]?.email?.message
                }
                w="90%"
                classNames={{ input: classes.input }}
                placeholder={t<string>('invite.suppliers.input.placeholder')}
                label={t<string>('common.email')}
              />
              {fields.length > 1 ? (
                <ActionIcon
                  size="lg"
                  onClick={() => remove(index)}
                  data-error={isInputError}
                  className={classes.icon}
                >
                  <Beta.Icon icon="trash" size="sm" />
                </ActionIcon>
              ) : (
                <ActionIcon
                  size="lg"
                  onClick={() => append({ ...defaultValues })}
                  data-error={isInputError}
                  className={classes.icon}
                >
                  <Beta.Icon icon="plus" size="sm" />
                </ActionIcon>
              )}
            </Flex>
          );
        })}
        {fields.length > 1 && (
          <Flex w="100%" justify="flex-end">
            <ActionIcon
              size="lg"
              className={classes.icon}
              onClick={() => append({ ...defaultValues })}
            >
              <Beta.Icon icon="plus" size="sm" />
            </ActionIcon>
          </Flex>
        )}
      </Stack>
      <Flex justify="end" gap="sm" mt="lg">
        <Button
          variant="outline"
          size="sm"
          onClick={cancel}
          className={classes.button}
        >
          Cancel
        </Button>
        <Button size="sm" type="submit">
          Invite
        </Button>
      </Flex>
    </form>
  );
};
