import React, { memo, useCallback, useMemo, useRef, useState } from 'react';
import {
  BooleanParam,
  NumberParam,
  QueryParamProvider,
  StringParam,
} from 'use-query-params';
import MapboxDraw from '@mapbox/mapbox-gl-draw';
import type { FeatureCollection } from 'geojson';
import {
  Icon,
  StyledTooltip,
  Text,
  Flex,
  ActionIcon,
} from '@liveeo/component-library';
import { InteractiveMap } from './InteractiveMap';
import { useTranslation } from 'react-i18next';
import { Link, useLocation } from 'react-router-dom';
import { MapProvider } from 'react-map-gl';
import { PlotRoutes } from './PlotRoutes';
import {
  OpenSourceParam,
  PlotsParam,
  PrecisionParam,
  usePlotState,
  useToggleAside,
} from '../../hooks';
import { PlotsProvider } from '../../contexts/PlotsContext';
import { SideNavRoutes } from './SideNavRoutes';
import classes from './Map.module.css';

type ButtonHeaderProps = {
  mode: string | undefined;
};

const DrawPolygonButton = memo(({ mode }: ButtonHeaderProps) => {
  const { t } = useTranslation();
  const location = useLocation();

  return (
    <div className={classes['btn-container']}>
      {mode === 'draw_polygon' ? (
        <StyledTooltip
          label={
            <Text c="colors.black400">
              {t<string>('plots.draw.instructions.text')}
            </Text>
          }
          position="bottom"
          multiline
          style={{ width: 220 }}
          withArrow
          transitionProps={{ transition: 'fade', duration: 200 }}
          dark
        >
          <ActionIcon
            component={Link}
            to={`/map/plots/${location.search}`}
            replace
            className={classes['icon-container']}
          >
            <Icon icon="xmark" color="dark.0" />
          </ActionIcon>
        </StyledTooltip>
      ) : (
        <ActionIcon
          component={Link}
          replace
          to={`/map/plots/draw-plot${location.search}`}
          className={classes['icon-container']}
        >
          <div className={classes.drawIcon} />
        </ActionIcon>
      )}
    </div>
  );
});

export const Map = () => {
  const state = usePlotState();
  const [features, setFeatures] = useState<FeatureCollection | any>({});
  const { isAsideOpen } = useToggleAside();

  const draw = useRef(
    new MapboxDraw({
      displayControlsDefault: true,
    })
  );

  const onDrawPlot = useCallback((e: any) => {
    setFeatures(e.features[0]);
  }, []);

  const onDeletePlot = useCallback(() => {
    draw.current.deleteAll();
    draw.current.changeMode(state?.mode || 'simple_select');
    setFeatures({});
  }, [state]);

  const queryParams = useMemo(
    () => ({
      desc: BooleanParam,
      ex: NumberParam,
      gFC2020: OpenSourceParam,
      la: NumberParam,
      ln: NumberParam,
      pan: BooleanParam,
      pg: NumberParam,
      plots: PlotsParam,
      precision: PrecisionParam,
      sel: StringParam,
      sort: StringParam,
      z: NumberParam,
    }),
    []
  );

  const memoizedFeatures = useMemo(() => features, [features]);

  return (
    <QueryParamProvider
      options={{
        params: queryParams,
        removeDefaultsFromUrl: false,
        updateType: 'replaceIn',
      }}
    >
      <PlotsProvider>
        <MapProvider>
          <Flex>
            {isAsideOpen && (
              <div className={classes['side-panel']}>
                <SideNavRoutes />
              </div>
            )}
            <DrawPolygonButton mode={state?.mode} />
            <InteractiveMap
              draw={draw}
              onDrawPlot={onDrawPlot}
              onDeletePlot={onDeletePlot}
              state={state}
            />
            <PlotRoutes
              draw={draw}
              features={memoizedFeatures}
              onDeletePlot={onDeletePlot}
            />
          </Flex>
        </MapProvider>
      </PlotsProvider>
    </QueryParamProvider>
  );
};
