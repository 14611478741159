import { useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';
import { usePlots, useResetParams, useUser } from '../../hooks';
import {
  Button,
  Grid,
  Stack,
  Title,
  Text,
  Image,
} from '@liveeo/component-library';
import {
  deforestationDisturbance,
  deforestationRiskMapping,
  hasPrecisionAnalysis,
} from '../../helper';
import SUPPLIERS_ICON from '../../../assets/suppliers-icon.svg';
import classes from './CollapsedRow.module.css';

type Props = {
  riskScore: number | undefined;
  supplierName: string;
  supplierId: string | null;
  plotId: string;
  isSupplierView: boolean;
  status?: string;
};

const PrecisionResults = ({ riskScore, status }: Partial<Props>) => {
  const { t } = useTranslation();
  return (
    <>
      <Grid.Col span={5}>
        <Stack gap={10}>
          <Text ta="center">
            {/* 
              This is a temporary solution while our precision riskOfDeforestation score 
              results are a binary number 0 or 1. Ideally this will change to a decimal
              number between 0 and 1 to match the OS riskOfDeforestation score.
            */}
            {status !== 'PENDING' && (
              <>
                {typeof riskScore === 'number' && riskScore > 0
                  ? 'Deforestation Detected'
                  : 'No Deforestation Detected'}
              </>
            )}
          </Text>
          <Text ta="center">--</Text>
        </Stack>
      </Grid.Col>
      <Grid.Col span={3}>
        <Stack>
          <Text
            ta="center"
            className={classes.text}
            data-hidden={typeof riskScore === 'undefined'}
          >
            {t(`common.status.${deforestationRiskMapping(riskScore)}`)}
          </Text>
          <Text ta="center">--</Text>
        </Stack>
      </Grid.Col>
    </>
  );
};

const OpenSourceResults = ({ riskScore }: Partial<Props>) => {
  const { t } = useTranslation();
  return (
    <>
      <Grid.Col span={4}>
        <Stack>
          <Text ta="center">{t('analysis.purchase')}</Text>
          <Text ta="center">
            {typeof riskScore === 'undefined'
              ? '--'
              : t(`analysis.disturbance`, {
                  percentage: deforestationDisturbance(riskScore),
                })}
          </Text>
        </Stack>
      </Grid.Col>
      <Grid.Col span={4}>
        <Stack gap={10}>
          <Button
            size="compact-sm"
            to="https://live-eo.com/tradeaware-contact"
            component={Link}
            target="_blank"
          >
            {t<string>('analysis.specialist')}
          </Button>
          <Text
            className={classes.text}
            data-hidden={typeof riskScore === 'undefined'}
          >
            {t(`common.status.${deforestationRiskMapping(riskScore)}`)}
          </Text>
        </Stack>
      </Grid.Col>
    </>
  );
};

export const CollapsedRow = ({
  riskScore,
  supplierName,
  supplierId,
  plotId,
  isSupplierView,
}: Props) => {
  const { t } = useTranslation();
  const { data: user } = useUser();
  const { resetParams } = useResetParams();
  const { findPlotById } = usePlots();
  const plot = findPlotById(plotId);
  return supplierId && plotId ? (
    <Stack className={classes.root}>
      <Text size="sm" fs="italic">
        {t('analysis.title')}
      </Text>
      <Grid grow>
        <Grid.Col span={4}>
          <Stack>
            <Title order={6}>{t('analysis.layers.precision')}</Title>
            <Title order={6}>{t('analysis.layers.global')}</Title>
          </Stack>
        </Grid.Col>
        {hasPrecisionAnalysis(plot) ? (
          <PrecisionResults
            riskScore={riskScore}
            status={plot?.mostRecentAnalysis?.status}
          />
        ) : (
          <OpenSourceResults riskScore={riskScore} />
        )}
      </Grid>
      {!isSupplierView && supplierId !== user?.businessId && (
        <Button
          leftSection={<Image src={SUPPLIERS_ICON} w={15} />}
          className={classes.button}
          component={Link}
          to={`../suppliers/${supplierId}?${resetParams()}&sel=${plotId}`}
        >
          {supplierName}
        </Button>
      )}
    </Stack>
  ) : null;
};
