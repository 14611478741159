import { useFetchApi, useNotification } from './';
import { useMutation, useQuery, useQueryClient } from '@tanstack/react-query';
import { ClientSecret } from '../shared/types';

export const useM2MClientSecret = (clientId?: string) => {
  const fetch = useFetchApi();
  const queryClient = useQueryClient();
  const { apiError } = useNotification();

  const {
    data: clientSecret,
    isLoading,
    isError,
  } = useQuery({
    queryKey: ['clientSecret', clientId],
    queryFn: () =>
      fetch(`auth/machine-to-machine-clients/${clientId}/secret`, {
        method: 'GET',
      }) as Promise<ClientSecret>,
    onError: () => apiError(),
    enabled: !!clientId,
    refetchOnWindowFocus: false,
  });

  const rotateMutation = useMutation({
    mutationKey: ['clientSecret', clientId],
    mutationFn: (clientId: string) =>
      fetch(`auth/machine-to-machine-clients/${clientId}/secret`, {
        method: 'POST',
        body: JSON.stringify({}),
      }) as Promise<ClientSecret>,
  });

  const rotateSecret = (clientId: string) =>
    rotateMutation.mutateAsync(clientId, {
      onSuccess: () =>
        queryClient.invalidateQueries({
          queryKey: ['clientSecret', clientId],
        }),
      onError: () => apiError(),
    });

  return { clientSecret, isLoading, isError, rotateSecret };
};
