import { useTranslation } from 'react-i18next';
import { Box, Text, ConfirmationModal } from '@liveeo/component-library';
import { usePlot, useTableState } from '../../hooks';
import { useLocation, useNavigate } from 'react-router-dom';

type Props = {
  name: string;
  plotId: string;
};

export const DeletePlotModal = ({ name, plotId }: Props) => {
  const { t } = useTranslation();
  const { deletePlot } = usePlot();
  const { setTableState } = useTableState();
  const navigate = useNavigate();
  const location = useLocation();

  const redirectToMap = () => {
    navigate(
      {
        pathname: '/map/plots',
        search: location.search,
      },
      {
        replace: true,
      }
    );
    setTableState({ sel: plotId });
  };

  return (
    <ConfirmationModal
      title={t<string>('plots.table.confirm.title').toString() + ':'}
      isOpen={true}
      cancel={redirectToMap}
      confirm={() => {
        if (plotId) deletePlot(plotId);
      }}
      translation={{ cancel: 'common.cancel', delete: 'common.delete' }}
    >
      <Box w={350}>
        <Text size="xl" truncate="end" fw={500}>
          {name || ''}
        </Text>
      </Box>
      {t<string>('plots.table.confirm.text')}
    </ConfirmationModal>
  );
};
