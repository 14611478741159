import { useMemo, Fragment } from 'react';
import { useTranslation } from 'react-i18next';
import { GenericErrorBoundary } from '../../shared/components/GenericErrorBoundary';
import { readableId } from '@liveeo/helpers';
import {
  SortingState,
  createColumnHelper,
  flexRender,
  getCoreRowModel,
  getPaginationRowModel,
  getSortedRowModel,
  getExpandedRowModel,
  useReactTable,
  functionalUpdate,
  OnChangeFn,
} from '@tanstack/react-table';
import {
  Icon,
  Text,
  UnstyledButton,
  StyledTooltip,
  Menu,
} from '@liveeo/component-library';
import { Table } from '../../shared/components/Table';
import { useResetParams, useTableState } from '../../hooks';
import { useNavigate } from 'react-router-dom';
import { useFlags } from '../../contexts/FlagsContext';

type Props = {
  buyers: any[] | undefined; // TODO create buyer type
  height: number;
};

const columnHelper = createColumnHelper<any>();

export const BuyersTable = ({ buyers, height }: Props) => {
  const { t } = useTranslation();
  const { tableState, setTableState, sorting } = useTableState();
  const { COMMERCIAL_GRAPH_v2 } = useFlags();
  const { resetParams } = useResetParams();
  const navigate = useNavigate();
  const selectedRowId = tableState.sel;
  const pageIndex = tableState.pg as any;

  const columns = useMemo(() => {
    return COMMERCIAL_GRAPH_v2
      ? [
          columnHelper.accessor('name', {
            header: t('common.name') ?? '',
            cell: (info) => (
              <StyledTooltip label={info.getValue() || ''} dark>
                <Text size="sm" c="colors.black400">
                  {readableId(info.getValue()) || ''}
                </Text>
              </StyledTooltip>
            ),
          }),
          columnHelper.display({
            id: 'actions',
            cell: ({ row }) => (
              <Menu position="bottom-end">
                <Menu.Target>
                  <UnstyledButton
                    w={10}
                    ta="center"
                    onClick={(e) => e.stopPropagation()}
                  >
                    <Icon icon="ellipsis-vertical" color="dark.0" />
                  </UnstyledButton>
                </Menu.Target>
                <Menu.Dropdown bg="dark.7">
                  <Menu.Item
                    leftSection={<Icon icon="edit" size="xs" />}
                    onClick={(e) => e.stopPropagation()}
                  >
                    {t<string>('buyers.table.edit')}
                  </Menu.Item>
                  <Menu.Divider />
                  <Menu.Item
                    leftSection={<Icon icon="trash" size="xs" />}
                    onClick={(e) => e.stopPropagation()}
                  >
                    {t<string>('buyers.table.invite')}
                  </Menu.Item>
                </Menu.Dropdown>
              </Menu>
            ),
          }),
        ]
      : [
          columnHelper.accessor('name', {
            header: t('common.name') ?? '',
            cell: (info) => (
              <StyledTooltip label={info.getValue() || ''} dark>
                <Text ta="left" size="sm" c="colors.black400">
                  {readableId(info.getValue()) || ''}
                </Text>
              </StyledTooltip>
            ),
          }),
        ];
  }, [t]);

  const table = useReactTable({
    data: buyers || [],
    columns,
    getCoreRowModel: getCoreRowModel(),
    getSortedRowModel: getSortedRowModel(),
    getExpandedRowModel: getExpandedRowModel(),
    state: {
      sorting,
      pagination: {
        pageIndex,
        pageSize: 20,
      },
    },
    getRowCanExpand: () => true,
    onSortingChange: (updaterFunction: OnChangeFn<SortingState> | any) => {
      const [newValue] = updaterFunction(sorting);
      if (newValue) {
        // reset table state when sorting headers used
        setTableState({
          pg: 0,
          sel: undefined,
          ex: undefined,
          sort: newValue.id,
          desc: newValue.desc,
        });
      }
    },
    getPaginationRowModel: getPaginationRowModel(),
    onPaginationChange: (updaterFunction) => {
      const newValue = functionalUpdate(updaterFunction, {
        pageIndex,
        pageSize: 20,
      });
      setTableState({
        pg: newValue.pageIndex,
        // reset selected plot and extended state when using table pagination
        sel: undefined,
        ex: undefined,
      });
    },
    enableSortingRemoval: false,
    autoResetPageIndex: false,
  });

  return (
    <Table height={height}>
      <Table.Header>
        {table.getHeaderGroups().map((headerGroup) => (
          <Table.HeaderRow key={headerGroup.id}>
            {headerGroup.headers.map((header) => (
              <Table.HeaderCell
                key={header.id}
                onClick={header.column.getToggleSortingHandler()}
              >
                {/* span to avoid console warning: validateDOMnesting(...): <div> cannot appear as a descendant of <p> */}
                <Table.HeaderTitle>
                  {header.isPlaceholder
                    ? null
                    : flexRender(
                        header.column.columnDef.header,
                        header.getContext()
                      )}
                </Table.HeaderTitle>
                {header.column.getCanSort() ? <Table.SortButtons /> : null}
              </Table.HeaderCell>
            ))}
          </Table.HeaderRow>
        ))}
      </Table.Header>
      <Table.Body>
        <GenericErrorBoundary>
          {table.getRowModel().rows.map((row) => {
            return (
              <Fragment key={row.id}>
                <Table.Row
                  isSelected={selectedRowId === row.original.id}
                  onClick={() =>
                    navigate(`../buyers/${row.original.id}?${resetParams()}`, {
                      replace: true,
                    })
                  }
                >
                  {row.getVisibleCells().map((cell) => (
                    <Table.Cell key={cell.id}>
                      {flexRender(
                        cell.column.columnDef.cell,
                        cell.getContext()
                      )}
                    </Table.Cell>
                  ))}
                </Table.Row>
              </Fragment>
            );
          })}
        </GenericErrorBoundary>
      </Table.Body>
      <Table.FooterPanel table={table} limit={20} />
    </Table>
  );
};
