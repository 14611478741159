import { useQuery } from '@tanstack/react-query';
import { useNotification } from './useNotification';
import { useFetchApi } from './useFetchApi';
import { BusinessInvitation } from '../shared/types';
import { InviteStatus } from './useInvitationStatus';

export const useBusinessInvitations = (status: InviteStatus | undefined) => {
  const fetch = useFetchApi();
  const { apiError } = useNotification();

  const { data, isLoading } = useQuery({
    queryKey: ['business-invitations', status],
    queryFn: () =>
      fetch(
        `business-invitations?status=${status}&includes=SENDER_BUSINESS`
      ) as Promise<BusinessInvitation[]>,
    onError: apiError,
    enabled: !!status,
    refetchOnWindowFocus: false,
  });

  return { data, isLoading };
};
