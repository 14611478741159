import { useMutation, useQueryClient } from '@tanstack/react-query';
import { useFetchApi } from './';
import { AddPlot, Plot } from '../shared/types';
import { useCallback, useMemo } from 'react';
import { useNotification } from './useNotification';
import { useLocation, useNavigate } from 'react-router-dom';
import { deforestationRiskMapping } from '../helper';
import { usePlotsContext } from '../contexts/PlotsContext';

export const usePlots = () => {
  const { data, isLoading, error } = usePlotsContext();
  const fetch = useFetchApi();
  const queryClient = useQueryClient();
  const { apiError, successfullyCreated } = useNotification();
  const navigate = useNavigate();
  const location = useLocation();

  const createMutation = useMutation({
    mutationKey: ['plots'],
    mutationFn: (payload: AddPlot) =>
      fetch('plots', {
        method: 'POST',
        body: JSON.stringify(payload),
      }),
  });

  const createPlot = (payload: AddPlot) =>
    createMutation.mutate(
      { ...payload, countryCode: 'ID' }, //TODO remove this when BE is ready to add this property
      {
        onSuccess: async (plot: any) => {
          successfullyCreated('plot');
          queryClient.setQueryData(
            ['plots'],
            (prevPlots: Plot[] | undefined) => prevPlots && [plot, ...prevPlots]
          );
          // We call this endpoint to request an analysis once the plot has been created
          await requestPlotAnalysis(plot.id, plot.name);
          queryClient.setQueryData(['plot'], plot);
          queryClient.invalidateQueries({ queryKey: ['plots'] });
          navigate(
            {
              pathname: `/map/plots/edit-plot/${plot.id}`,
              search: location.search,
            },
            { replace: true }
          );
        },
        onError: apiError,
      }
    );

  const requestPlotAnalysis = async (plotId: string, name: string) => {
    try {
      await fetch(`plots/${plotId}/analyses`, {
        method: 'POST',
        body: JSON.stringify({
          name,
          type: 'EUFOROBS',
          metadata: {},
        }),
      });
    } catch (e) {
      console.error(e);
    }
  };

  const findPlotById = useCallback(
    (plotId: string) => {
      if (!plotId) return;
      const plot = data?.find((plot: Plot) => plot.id === plotId);
      queryClient.setQueryData(['plot'], plot);
      return plot;
    },
    [data, queryClient]
  );

  const plotsAsFeatureCollection = useMemo(() => {
    return {
      type: 'FeatureCollection',
      features: data?.map((plot: Plot, i: number) => ({
        id: i, // Mapbox requires the feture id to be a number so we can't use the plotId
        type: 'Feature',
        geometry: plot.geometry,
        properties: {
          plotId: plot.id,
          riskOfDeforestation: deforestationRiskMapping(
            plot.mostRecentAnalysis?.riskOfDeforestation
          ),
        },
      })),
    };
  }, [data]);

  return {
    createPlot,
    data,
    error,
    findPlotById,
    isLoading,
    plotsAsFeatureCollection,
    requestPlotAnalysis,
  };
};
