import { useTranslation } from 'react-i18next';
import { useToggle } from '@liveeo/helpers';
import { SidePanelContainer } from './SidePanelContainer';
import { Button, Stack, Title, Text, Modal } from '@liveeo/component-library';
import { BuyersTable } from '../Buyers/BuyersTable';
import { useBuyers, useToggleAside } from '../../hooks';
import { Table } from '../../shared/components/Table';
import classes from './SidePanelContainer.module.css';
import { InviteBuyersForm } from '../Buyers/InviteBuyersForm';
import { heightOfSidePanelContent } from '../../helper/side-panel-height';
import { useTracking } from '../../hooks';
import { useEffect } from 'react';
import { useFlags } from '../../contexts/FlagsContext';

type EmptyTableProps = {
  toggleModal: () => void;
};

const EmptyTable = ({ toggleModal }: EmptyTableProps) => {
  const { t } = useTranslation();
  const { toggleAside } = useToggleAside();
  const { COMMERCIAL_GRAPH_v2 } = useFlags();

  return (
    <Table.EmptyState>
      <Stack align="center" w="63%" gap={20}>
        {/* 
          This is a temp text change to show the buyers table with more applicable copy 
          that does not mention inviting buyers as we are not releasing this feature yet.
          
          As it is only temp we do not need to add the translation keys to Lokalize.
        */}
        {COMMERCIAL_GRAPH_v2 ? (
          <>
            <Stack gap={0}>
              <Title ta="center" order={4}>
                {t('buyers.table.empty.title')}
              </Title>
              <Text ta="center">{t('buyers.table.empty.text')}</Text>
            </Stack>
            <Button
              variant="outline"
              className={classes.button}
              onClick={toggleModal}
            >
              {t<string>('buyers.table.empty.button')}
            </Button>
          </>
        ) : (
          <Stack gap={0}>
            <Title ta="center" order={4}>
              No buyers have invited you yet
            </Title>
            <Text ta="center">
              No one has invited you as a supplier quite yet. Start making
              connections and buyers will appear here soon!
            </Text>
          </Stack>
        )}
      </Stack>
      <Table.EmptyState.Footer handleClose={() => toggleAside(undefined)} />
    </Table.EmptyState>
  );
};

export const BuyersTablePanel = () => {
  const { t } = useTranslation();
  const { trackEvent } = useTracking();
  const [isModalOpen, toggleModal] = useToggle();
  const { COMMERCIAL_GRAPH_v2 } = useFlags();
  const { data: buyers, isLoading } = useBuyers();
  const isEmpty = !isLoading && !buyers?.length;

  useEffect(() => {
    trackEvent('Buyer', {
      step: 'buyer-page',
      action: 'open',
    });
  }, [trackEvent]);

  return (
    <>
      <SidePanelContainer>
        <SidePanelContainer.Header>
          <SidePanelContainer.Title title={t<string>('buyers.table.title')} />
          {COMMERCIAL_GRAPH_v2 && (
            <Button
              variant="outline"
              size="compact-sm"
              className={classes.button}
              onClick={() => toggleModal()}
            >
              {t<string>('common.invite')}
            </Button>
          )}
        </SidePanelContainer.Header>

        {isLoading ? (
          <Table.Loader height={heightOfSidePanelContent} />
        ) : isEmpty ? (
          <EmptyTable toggleModal={toggleModal} />
        ) : (
          <BuyersTable
            buyers={
              buyers?.map((connection) => connection.sourceBusiness) || []
            }
            height={heightOfSidePanelContent}
          />
        )}
      </SidePanelContainer>
      {COMMERCIAL_GRAPH_v2 && (
        <Modal
          title={
            <div>
              <Title order={3}>{t<string>('invite.buyers.title')}</Title>
            </div>
          }
          opened={isModalOpen}
          onClose={toggleModal}
          centered
        >
          <Stack mb="sm">
            <Text>{t<string>('invite.buyers.text')}</Text>
            <InviteBuyersForm cancel={toggleModal} />
          </Stack>
        </Modal>
      )}
    </>
  );
};
