import React, { createContext, useContext, useMemo } from 'react';
import { useQuery } from '@tanstack/react-query';
import { Plot } from '../shared/types';
import { useFetchApi, useNotification } from '../hooks';

interface PlotsContextProps {
  data: Plot[] | undefined;
  isLoading: boolean;
  error: any;
}

const PlotsContext = createContext<PlotsContextProps | undefined>(undefined);

export const PlotsProvider: React.FC<{ children: React.ReactNode }> = ({
  children,
}) => {
  const fetch = useFetchApi();
  const { apiError } = useNotification();

  const { data, isLoading, error } = useQuery<Plot[]>({
    queryKey: ['plots'],
    queryFn: async () =>
      fetch('plots?includes=suppliers,mostRecentAnalysis') as Promise<Plot[]>,
    onError: apiError,
    refetchOnWindowFocus: false,
  });

  const value = useMemo(
    () => ({ data, isLoading, error }),
    [data, isLoading, error]
  );

  return (
    <PlotsContext.Provider value={value}>{children}</PlotsContext.Provider>
  );
};

export const usePlotsContext = () => {
  const context = useContext(PlotsContext);
  if (!context) {
    throw new Error('usePlotsContext must be used within a PlotsProvider');
  }
  return context;
};
