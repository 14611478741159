import { useQuery, useQueryClient } from '@tanstack/react-query';
import { useFetchApi } from './useFetchApi';
import { useNotification } from './useNotification';
import { Plot } from '../shared/types';
import { useMap, LngLatLike } from 'react-map-gl';
import { getBbox, BOUNDS_PADDING } from '../helper';
import { useTableState } from './useTableState';
import { useCallback } from 'react';

export const useSupplierPlots = (supplierId: string) => {
  const fetch = useFetchApi();
  const { apiError } = useNotification();
  const queryClient = useQueryClient();
  const { deforestationMap: map } = useMap();
  const { tableState, setTableState } = useTableState();

  const fetchSupplierPlots = useCallback(async () => {
    try {
      const plots = (await fetch(
        `businesses/${supplierId}/plots?includes=suppliers,mostRecentAnalysis`
      )) as Plot[];
      const plot = plots?.length ? plots[0] : null;
      // replacing the plots cache when suppliers plots have been requested
      // in order to show only plots belonging to a selected supplier on the map
      queryClient.setQueryData(['plots'], plots);
      // update map to location of first plot in table with it selected in table
      if (!tableState.sort && !tableState.sel && plot) {
        const bbox = getBbox(plot.geometry);
        if (bbox.length) {
          map?.fitBounds(bbox as [LngLatLike, LngLatLike], {
            duration: 0,
            padding: BOUNDS_PADDING,
          });
        }
        setTableState({ sel: plot.id });
      }
      return plots;
    } catch (error) {
      apiError();
      throw error;
    }
  }, [
    fetch,
    supplierId,
    queryClient,
    tableState,
    map,
    setTableState,
    apiError,
  ]);

  const { data, isLoading } = useQuery({
    queryKey: ['supplier-plots', supplierId],
    queryFn: fetchSupplierPlots,
    enabled: !!supplierId,
    onError: apiError,
    refetchOnWindowFocus: false,
  });

  return { data, isLoading };
};
