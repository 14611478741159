import { Icon, Text, Group, Loader, Center } from '@liveeo/component-library';
import { Statuses, StatusColor } from '../types';
import { useTranslation } from 'react-i18next';
import styled from '@emotion/styled';

type Props = {
  text: Statuses;
};

type IconProps = {
  color: StatusColor;
};

const StatusIcon = ({ color }: IconProps) => (
  <Icon icon="circle" width={8} color={color} />
);

const StatusText = ({ text }: Props) => (
  <Text c="white" size="sm" fw={400} span>
    {text}
  </Text>
);

export const StatusIndicator = ({ text }: Props) => {
  const { t } = useTranslation();
  switch (text) {
    case 'high':
      return (
        <Group justify="center" gap="xs">
          <StatusIcon color="#D4111B" />
          <StatusText text={t('common.status.high')} />
        </Group>
      );
    case 'low':
      return (
        <Group justify="center" gap="xs">
          <StatusIcon color="#512BB5" />
          <StatusText text={t('common.status.low')} />
        </Group>
      );
    case 'medium':
      return (
        <Group justify="center" gap="xs">
          <StatusIcon color="#FFAB1A" />
          <StatusText text={t('common.status.medium')} />
        </Group>
      );
    case 'processing':
      return (
        <Center>
          <StatusText text={t('common.status.processing')} />
        </Center>
      );
    default:
      return <StatusText text={text} />;
  }
};
